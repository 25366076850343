@import "../../../../_theme_variables";

.sociallogininfo .social-avatar  {
	float:left;
	max-width:150px;
	margin:10px 15px 15px 0;
}

.btn.btn-login-facebook, .btn.btn-login-google {
	clear:both;
	display:block;
	color:#fff;
	font-size:13px !important;
	border-radius:4px;
	padding:10px 20px;
	margin-bottom:10px;
	@include border-radius(0);
}

.btn.btn-login-facebook:before, .btn.btn-login-google:before{
	font-family:'FontAwesome';
	padding-right:5px;
	font-size:15px;
}

.btn.btn-login-facebook:before {
	content:'\f09a';
}	

.btn.btn-login-facebook {
	background:#3b5998;
	border-color:#3b5998;
}
.btn.btn-login-facebook:hover {
	background:#fff;
	color:#3b5998;
	border-color:#3b5998;
}
.btn.btn-login-google:before {
	content:'\f1a0';
}
.btn.btn-login-google {
	background:#df4a32;
	border-color:#df4a32;
}
.btn.btn-login-google:hover {
	background:#fff;
	color:#df4a32;
	border-color:#df4a32;
}